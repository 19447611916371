import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { FirebaseContext } from '../FirebaseContext'
import { ContactSubmission } from '../types/Types'

export const useContactSubmissions = () => {
  const { db } = React.useContext(FirebaseContext)
  const query = db.collection('contact-submissions')
  const options = {
    snapshotListenOptions: { includeMetadataChanges: false },
    idField: 'id',
  }
  return useCollectionData<ContactSubmission>(query, options)
}
