import React from 'react'

import { Image, Carousel } from 'react-bootstrap'
import './Testimonials.scss'
// @ts-ignore
import { Fade } from 'react-reveal'
// @ts-ignore
import HeadShake from 'react-reveal/HeadShake'

type Testimonial = {
  imageUrl: string
  quote: Quote
}

type Quote = {
  body: React.ReactNode
  author: string
  role?: string
}

export const testimonials: Testimonial[] = [
  {
    imageUrl: "/shav.png",
    quote: {
      body: (
        <p>
          I first started playing frisbee when I joined Flinders Ultimate in 2019, and that sparked a love for the sport. 
          Playing Ultimate Frisbee has got to be one of the best decisions I’ve made, 
          and it has been one of my greatest sources of joy over the past two years! 
          The community is incredibly supportive and friendly, and I can’t think of a more nurturing environment to grow 
          and play in.

        </p>
      ),
      author: `Sharveena`,
      role: `Nationals Athlete`
    }
  },
  {
    imageUrl: "/brin.png",
    quote: {
      body: (
        <>
          <p>
            Although it took years of convincing for me to finally try it out, playing frisbee was one of my greatest sporting decisions. Having a friendly and supportive community help new players regardless of if they are teammates or opposition made the transition to a new sport very smooth.
          </p>
          <p>
            Joining Flinders Ultimate was the first step of my frisbee journey, and I am still getting better and better every day!
          </p>
        </>
      ),
      author: `Brian`,
      role: `Nationals Athlete`
    }
  }
]

  // {
  //   imageUrl: "/shav.png",
  //   quote: {
  //     body: (
  //       <p>
  //         I started playing Social League in 2017 and I haven't looked back
  //         since. I've met some of my closest friends through this club and
  //         it's given me so many opportunities to develop as a player.
  //       </p>
  //     ),
  //     author: `Alfie`,
  //     role: `Club President`
  //   }
  // },

export const Testimonials: React.FC = () => {

  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const active = testimonials[selectedIndex]

  const handlePrev = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(testimonials.length - 1)
    } else {
      setSelectedIndex(selectedIndex - 1)
    }
  }

  const handleNext = () => {
    if (selectedIndex === testimonials.length - 1) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(selectedIndex + 1)
    }
  }

  return (
    <div className="Testimonials">
      <Fade bottom>
        <HeadShake spy={selectedIndex}>
          <div className="Image">
            <Image rounded fluid src={active.imageUrl} alt={active.quote.author}></Image>
          </div>
        </HeadShake>
        <div className="TextArea">
          <h2>Testimonials</h2>
          <div className="QuoteArea">
            <Quote
              body={active.quote.body}
              role={active.quote.role}
              author={active.quote.author}
            />
            <div className="ControlBar">
              <div className="ControlButton" onClick={() => { handlePrev() }}>Prev</div>
              <div className="ControlNodeContainer">
                {testimonials.map((it, i) => {
                  return <div
                    key={`testimonial-${i}`}
                    className={`ControlNode ${selectedIndex === i ? 'selected' : ''}`}
                    onClick={() => {
                      setSelectedIndex(i)
                    }}
                  />
                })}
              </div>
              <div className="ControlButton" onClick={() => { handleNext() }}>Next</div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export const Quote: React.FC<Quote> = (props) => {
  const { body, role, author } = props

  return (
    <figure className="Quote">
      <blockquote>
        {body}
      </blockquote>
      <figcaption>
        &mdash;{author}{role && <>, <cite>{role}</cite></>}
      </figcaption>
    </figure>
  )
}