import React from 'react'
import './SponsorCarousel.scss'

export const SponsorCarousel: React.FC = () => {
  return (
    <div className="SponsorCarousel">
      Flinders Ultimate is proudly sponsored by
      <hr />
      <div className="Carousel">
        <div className="Sponsor Major">Business A</div>
        <div className="Sponsor Major">Business B</div>
      </div>
      <i>Principal Sponsors</i>
      <hr />
      <div className="Carousel">
        <div className="Sponsor">Business C</div>
        <div className="Sponsor">Business D</div>
        <div className="Sponsor">Business E</div>
      </div>
      <i>Major Sponsors</i>
    </div>
  )
}
