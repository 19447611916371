import React from 'react'
import { useBlogPosts } from '../../hooks/useBlogPosts'
import { BlogPost, Player } from '../../types/Types'
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Spinner,
  Table,
} from 'react-bootstrap'
import { DateTime } from 'luxon'
import MdEditor from 'react-markdown-editor-lite'
import MarkdownIt from 'markdown-it'
import ReactDatePicker from 'react-datepicker'
import slugify from 'slugify'
import Select, {
  ActionMeta,
  SelectOptionActionMeta,
  OptionsType,
} from 'react-select'
import { usePlayers } from '../../hooks/usePlayers'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import firebase from 'firebase'
import { FirebaseContext } from '../../FirebaseContext'

export const Articles: React.FC = () => {
  const [posts, loading, error] = useBlogPosts()
  const [createPost, setCreatePost] = React.useState(false)

  return (
    <>
      <h3 style={{ marginBottom: 50 }}>Articles</h3>

      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Slug</th>
            <th>Title</th>
            <th>Published</th>
            <th>{/* Button Column */}</th>
          </tr>
        </thead>
        <tbody>
          {posts &&
            posts.map((it, i) => {
              return <BlogPostRow key={`blogrow-${i}`} post={it} />
            })}
        </tbody>
      </Table>
      <Button
        variant="success"
        onClick={() => {
          setCreatePost(true)
        }}
      >
        &#43; Create new post
      </Button>
      {createPost && <EditArticle post={null} setShow={setCreatePost} />}
    </>
  )
}

interface BlogPostRowProps {
  post: BlogPost
}

const BlogPostRow: React.FC<BlogPostRowProps> = (props) => {
  const { post } = props

  const [showEdit, setShowEdit] = React.useState(false)
  const [showDelete, setShowDelete] = React.useState(false)

  const dt = DateTime.fromSeconds(post?.date.seconds)

  return (
    <tr>
      <td>{dt.toLocaleString(DateTime.DATETIME_MED)}</td>
      <td>{post.slug}</td>
      <td>{post.title}</td>
      <td>
        <Form.Check
          checked={post.published}
          type="switch"
          id="PublishedSwitch"
          onChange={() => {
            // dispatch change
          }}
        />
      </td>
      <td>
        <Button
          variant="dark"
          onClick={() => {
            setShowEdit(true)
          }}
        >
          Edit
        </Button>
        {showEdit && <EditArticle setShow={setShowEdit} post={post} />}
        <Button
          variant="danger"
          onClick={() => {
            setShowDelete(true)
          }}
        >
          Delete
        </Button>
        {showDelete && <DeleteArticle setShow={setShowDelete} post={post} />}
      </td>
    </tr>
  )
}

interface DeleteArticleProps {
  post: BlogPost
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

interface EditArticleProps {
  post: BlogPost | null
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

interface MarkdownProps {
  text: string
  html: string
}

const DeleteArticle: React.FC<DeleteArticleProps> = (props) => {
  const { post, setShow } = props
  const { user: currentUser, db } = React.useContext(FirebaseContext)

  const handleClose = () => {
    setShow(false)
  }

  const handleDelete = () => {
    db.collection('posts').doc(post.id).delete()
    handleClose()
  }

  return (
    <Modal animation={false} show={true} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to permanently delete <b>'{post.title}'</b>?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditArticle: React.FC<EditArticleProps> = (props) => {
  const { post, setShow } = props
  const { user: currentUser, db, storage } = React.useContext(FirebaseContext)

  const seconds = Math.round(new Date().getTime() / 1000)
  const initialState: BlogPost = post
    ? post
    : {
        id: db.collection('posts').doc().id,
        content: '',
        date: new firebase.firestore.Timestamp(seconds, 0),
        title: '',
        imageUrl: '',
        slug: '',
        published: false,
        author: undefined,
      }

  const [editValue, setEditValue] = React.useState<BlogPost>(initialState)
  const [players, loading, error] = usePlayers()

  const options = {
    snapshotListenOptions: { includeMetadataChanges: false },
    idField: 'id',
  }

  const [author, l, e] = useDocumentDataOnce<Player>(editValue.author, options)

  const playerNames = players?.map((it) => {
    return {
      value: it.id,
      label: it.name,
    }
  })

  const mdParser = new MarkdownIt()

  const handleClose = () => setShow(false)

  const handleSave = () => {
    post
      ? db.collection('posts').doc(editValue.id).update(editValue)
      : db.collection('posts').doc(editValue.id).set(editValue)
    handleClose()
  }

  const handleInputFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const slug =
      e.target.name === 'title' ? slugify(value).toLowerCase() : editValue.slug

    setEditValue({
      ...editValue,
      [e.target.name]: value,
      slug: slug,
    })
  }

  const handleMarkdownEditorChange: (p: MarkdownProps) => void = ({
    html,
    text,
  }) => {
    const value = text
    setEditValue({
      ...editValue,
      content: value,
    })
  }

  const handleDatePickerChange = (d: Date) => {
    const millis = d.getTime()
    const seconds = Math.round(millis / 1000)
    const value = new firebase.firestore.Timestamp(seconds, 0)
    setEditValue({
      ...editValue,
      date: value,
    })
  }

  const handleAuthorChange = (
    value: {
      value: string
      label: string
    } | null,
    actionMeta: ActionMeta<{
      value: string
      label: string
    }>
  ) => {
    const ref: firebase.firestore.DocumentReference = db
      .collection('players')
      .doc(value?.value)
    setEditValue({
      ...editValue,
      author: ref,
    })
  }

  const toggleSwitch = () => {
    setEditValue({
      ...editValue,
      published: !editValue.published,
    })
  }

  const formIsInvalid =
    !editValue.author ||
    !editValue.title ||
    !editValue.imageUrl ||
    !editValue.content

  return (
    <Modal
      animation={false}
      show={true}
      onHide={handleClose}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col lg="auto">
              <Form.Group>
                <Form.Label>ID</Form.Label>
                <Form.Control disabled value={editValue.id} />
                <Form.Text muted>This is set automatically.</Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Slug</Form.Label>
                <Form.Control disabled value={editValue.slug} />
                <Form.Text muted>
                  A slug is a unique string which is generated based on the
                  Article's title. It is used to generate the full URL of a
                  given Article. i.e. flindersultimate.club/news/example-slug
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              value={editValue.title}
              onChange={handleInputFieldChange}
            />
          </Form.Group>
          <Form.Row>
            <Col lg="3">
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <br />
                <ReactDatePicker
                  onChange={handleDatePickerChange}
                  dateFormat="MMMM d, yyyy, h:mm aa"
                  showTimeInput
                  timeInputLabel="Time:"
                  selected={editValue.date.toDate()}
                  customInput={
                    <Form.Control
                      style={{ height: 38, width: '100%' }}
                    ></Form.Control>
                  }
                />
              </Form.Group>
            </Col>

            <Col lg="7">
              <Form.Group>
                <Form.Label>Author</Form.Label>
                <Select
                  options={playerNames}
                  value={author && { value: author.id, label: author.name }}
                  onChange={handleAuthorChange}
                />
                <Form.Text muted>
                  {loading && 'Loading players...'}
                  {error && error.message}
                  {players &&
                    'This is a list of players in the Player Data. You can search the options by typing.'}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col lg="2">
              <Form.Group>
                <Form.Label>Published?</Form.Label>
                <Form.Switch
                  name="published"
                  id="custom-switch"
                  checked={editValue.published}
                  onChange={toggleSwitch}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Image URL</Form.Label>
                <Form.Control
                  name="imageUrl"
                  onChange={handleInputFieldChange}
                  value={editValue.imageUrl}
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
        <Form.Label>Content</Form.Label>
        <MdEditor
          style={{ height: '500px' }}
          value={editValue.content}
          renderHTML={(text) => mdParser.render(text)}
          onChange={handleMarkdownEditorChange}
        />
        {formIsInvalid && (
          <Alert style={{ marginTop: 30 }} variant="danger">
            Please ensure all text fields have been filled out.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={formIsInvalid} variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
