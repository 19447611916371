import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import { Resources } from './pages/Resources'
import { Members } from './pages/Members'
import { Contact } from './pages/Contact'
import { News } from './pages/News'
import { Sponsors } from './pages/Sponsors'
import { UltimateFrisbee } from './pages/UltimateFrisbee'
import { Admin } from './pages/admin/Admin'
import { Login } from './pages/Login'
import { FirebaseContext } from './FirebaseContext'
import { ArticleLoader } from './pages/ArticleLoader'
import { ScrollToTop } from './components/ScrollToTop'
import { Page } from './components/Page'
import './App.scss'
import { NotFound } from './pages/NotFound'
import { AppStateContext } from './AppStateContext'
import { Helmet } from 'react-helmet-async'
// @ts-ignore
import MessengerCustomerChat from 'react-messenger-customer-chat'


const App: React.FC = () => {
  const { user } = React.useContext(FirebaseContext)
  const { setShowSponsors } = React.useContext(AppStateContext)

  if (document.location.search.includes('previewSponsors'))
    setShowSponsors(true)

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {user && (
          <Route path="/admin">
            <Admin />
          </Route>
        )}
        <Route path="/login">
          <Page title="Log in">
            <Login />
          </Page>
        </Route>
        <Route path="/news/:slug" component={ArticleLoader}></Route>
        <Route path="/news">
          <Page title="News">
            <News />
          </Page>
        </Route>
        <Route path="/resources">
          <Page title="Resources">
            <Resources />
          </Page>
        </Route>
        <Route path="/contact">
          <Page title="Contact">
            <Contact />
          </Page>
        </Route>
        {/* <Route path="/members">
          <Page title="Members">
            <Members />
          </Page>
        </Route>
        <Route path="/sponsors">
          <Page title="Sponsors">
            <Sponsors />
          </Page>
        </Route>
        <Route path="/ultimate">
          <Page title="Ultimate">
            <UltimateFrisbee />
          </Page>
        </Route> */}
        <Route exact path="/">
          <Page
            overrideContainer
          >
            <Home />
          </Page>
        </Route>
        <Route path="/">
          <Page title="Not Found">
            <NotFound />
          </Page>
        </Route>
      </Switch>
      {/* <MessengerCustomerChat
        pageId="325245030953782"
        appId="825856021649843"
        themeColor="#041E42"
      /> */}
    </Router>
  )
}

export default App
