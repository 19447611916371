import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Footer.scss'

export const Footer: React.FC = () => {
  return (
    <div className="Footer">
      <Container>
        <Row>
          <Col>
            <h5>Club</h5>
            <ul>
              <Link to="#">
                <li>Home</li>
              </Link>
              <Link to="#">
                <li>News</li>
              </Link>
              {/* <Link to="#">
                <li>Results</li>
              </Link> */}
              {/* <Link to="#">
                <li>Members</li>
              </Link> */}
              <Link to="#">
                <li>Policies</li>
              </Link>
              <Link to="#">
                <li>Contact</li>
              </Link>
            </ul>
          </Col>
          {/* <Col>
            <h5>About Us</h5>
            <ul> */}
              {/* <Link to="#">
                <li>About</li>
              </Link> */}
              {/* <Link to="#">
                <li>Policies</li>
              </Link>
              <Link to="#">
                <li>Contact</li>
              </Link> */}
              {/* <Link to="#">
                <li>Sponsors</li>
              </Link> */}
            {/* </ul>
          </Col> */}
          {/* <Col>
            <h5>Play With Us</h5>
            <ul>
              <Link to="#">
                <li>How to Play</li>
              </Link>
              <Link to="#">
                <li>Membership</li>
              </Link>
              <Link to="#">
                <li>Events</li>
              </Link>
              <Link to="#">
                <li>Teams</li>
              </Link>
            </ul>
          </Col> */}
          <Col>
            <h5>Socials</h5>
            <ul>
              <a rel="external" href="https://facebook.com/FlindersUltimate">
                <li>Facebook</li>
                <img
                  className="external"
                  src="/icons/ExternalLink-Light.svg"
                  alt="External Link Icon"
                />
              </a>
              <a rel="external" href="https://instagram.com/flindersultimate">
                <li>Instagram</li>
                <img
                  className="external"
                  src="/icons/ExternalLink-Light.svg"
                  alt="External Link Icon"
                />
              </a>
              <a
                rel="external"
                href="https://www.youtube.com/channel/UC1qsX33ULK7RrYne60sgXiA"
              >
                <li>Youtube</li>
                <img
                  className="external"
                  src="/icons/ExternalLink-Light.svg"
                  alt="External Link Icon"
                />
              </a>
              <a rel="external" href="https://twitter.com/FU_Ultimate">
                <li>Twitter</li>
                <img
                  className="external"
                  src="/icons/ExternalLink-Light.svg"
                  alt="External Link Icon"
                />
              </a>
            </ul>
          </Col>
          <Col sm="12" md="6">
            <img
              className="Logo"
              src="/logo-light.svg"
              alt="Flinders Ultimate Logo"
            />
            <p>
              © 2021 - <b>Flinders Ultimate</b>. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
