import React, { ChangeEventHandler } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Redirect } from 'react-router'
import { FirebaseContext } from '../FirebaseContext'

interface LoginProps {}

export const Login: React.FC<LoginProps> = (props) => {
  const [email, setEmail] = React.useState<string | null>(null)
  const [password, setPassword] = React.useState<string | null>(null)

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.currentTarget.value)
  }

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setPassword(e.currentTarget.value)
  }

  const { auth, user: currentUser } = React.useContext(FirebaseContext)

  const handleLogin = React.useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault()

      if (email == null || password == null) return

      try {
        await auth.signInWithEmailAndPassword(email, password)
      } catch (e) {
        console.warn(e)
      }
    },
    [email, password]
  )

  if (currentUser) {
    console.log('login successful')
    return <Redirect to="/admin" />
  }

  return (
    <Form>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type={'email'}
          onChange={handleEmailChange}
          placeholder={'cool-email@example.com'}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control onChange={handlePasswordChange} type={'password'} />
      </Form.Group>
      <Button variant="dark" type="button" onClick={handleLogin}>
        Log In
      </Button>
    </Form>
  )
}
