import firebase from 'firebase'
import React from 'react'
import { Button, Card, CardDeck, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useDownloadURL } from 'react-firebase-hooks/storage'
import { VerticalSpacer } from '../components/VerticalSpacer'
import { usePlayers } from '../hooks/usePlayers'
import { useBlogPosts } from '../hooks/useBlogPosts'
import { BlogPost, Player } from '../types/Types'
import Luxon, { DateTime } from 'luxon'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { Link } from 'react-router-dom'
import { FirebaseContext } from '../FirebaseContext'
import './News.scss'

export const News: React.FC = () => {
  const { auth } = React.useContext(FirebaseContext)
  const [posts, loading, error] = useBlogPosts()
  // TODO: Remove let
  let publishedCount = 0

  const isAuthenticated = !!auth.currentUser
  const postsToDisplay = isAuthenticated
    ? posts
    : posts?.filter((it) => it.published === true)
  postsToDisplay?.sort((a, b) => b.date.toMillis() - a.date.toMillis())

  return (
    <>
      <Helmet>
        <title>News - Flinders Ultimate</title>
      </Helmet>
      <div className="CardDeck">
        {postsToDisplay &&
          postsToDisplay.map((it, i) => {
            return (
              <NewsCard key={`newscard-${i}`} post={it} feature={i === 0} />
            )
          })}
      </div>
      {postsToDisplay?.length === 0 && 'Nothing to see here.'}
      {error && error.message}
      {loading && <Spinner animation="border" variant="primary" />}
    </>
  )
}

interface NewsCardProps {
  post: BlogPost
  feature?: boolean
}

const NewsCard: React.FC<NewsCardProps> = (props) => {
  const { post, feature } = props

  const previewLength = feature ? 300 : 100
  const snippet =
    post.content.substring(0, previewLength) +
    `${post.content.length > previewLength ? '...' : ''}`
  const preview = snippet.replace(/[`~@#$%^&*()_|+\=<>\{\}\[\]\\\/]/gi, '')
  const dt = DateTime.fromSeconds(post.date.seconds)

  const [author, loading, error] = useDocumentDataOnce<Player>(post.author, {
    idField: 'id',
  })

  return (
    <div
      className={`NewsCard ${!post.published ? 'Hidden' : ''} ${
        feature ? 'Feature' : ''
      }`}
    >
      <Link className="CardImage" to={`/news/${post.slug}`}>
        <img src={post.imageUrl} />
      </Link>
      <div className="CardBody">
        <div className="CardDetails">
          {!loading &&
            `${author?.name} — ${dt.toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}`}
        </div>
        {!feature ? <h4>{post.title}</h4> : <h1>{post.title}</h1>}
        <p>{preview}</p>
        {/* <VerticalSpacer size="sm"></VerticalSpacer> */}
        <Link to={`/news/${post.slug}`}>
          <Button variant="outline-primary" size={feature ? undefined : 'sm'}>
            Read Article
          </Button>
        </Link>
      </div>
    </div>
  )
}
