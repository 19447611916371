import React from 'react'
import { Container } from 'react-bootstrap'
import { FirebaseContext } from '../FirebaseContext'
import { AdminNavigation } from './AdminNavigation'
import { Footer } from './Footer'
import { Navigation } from './Navigation'
import { SponsorCarousel } from './SponsorCarousel'
import './Page.scss'
import { AppStateContext } from '../AppStateContext'
import { Helmet } from 'react-helmet-async'

interface PageProps {
  title?: string
  children: React.ReactNode
  overrideContainer?: boolean
}

export const Page: React.FC<PageProps> = (props) => {
  const { title, children, overrideContainer } = props
  const { user } = React.useContext(FirebaseContext)
  const { showSponsors } = React.useContext(AppStateContext)

  const content: React.ReactNode = <>
    {title && <h3 style={{ marginBottom: '2em' }}>{title}</h3>}
    {children}
    {showSponsors && <SponsorCarousel />}
  </>

  return (
    <>
      <Helmet>
        <title>{title ? `${title} - Flinders Ultimate` : 'Flinders Ultimate'}</title>
      </Helmet>
      {user && <AdminNavigation />}
      <Navigation />
      <div className="Page">
        {!overrideContainer ? <Container>{content}</Container> : <>{content}</>}

      </div>
      <Footer />
    </>
  )
}
