import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FirebaseContext } from '../FirebaseContext'

export const AdminNavigation: React.FC = () => {
  const { user, auth } = React.useContext(FirebaseContext)

  return (
    <div className="NavContainer AdminBar">
      <nav className="Nav AdminBar">
        <div className="NavSection">
          <div className="NavChild">
            <b>
              <Link to="/admin">Admin Panel 😎</Link>
            </b>
          </div>
          <div className="NavChild">User: {user?.email} </div>
        </div>
        <Button
          variant="outline-light"
          type="button"
          onClick={() => {
            auth.signOut()
          }}
          children={'Sign Out'}
          size="sm"
        />
      </nav>
    </div>
  )
}
