import React from 'react'
import './NotFound.scss'

export const NotFound = () => {
  return (
    <>
      <h1>Uh oh.</h1>
      <p>
        We couldn't find the page you were looking for. If you think this is an
        error, please shoot us an email at{' '}
        <a href="mailto:flindersultimate@gmail.com">
          flindersultimate@gmail.com
        </a>
      </p>
    </>
  )
}
