import React from 'react'

export interface AppStateContextShape {
  showSponsors: boolean
  setShowSponsors: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppStateContext = React.createContext({} as AppStateContextShape)

export const AppStateProvider: React.FC = (props) => {
  const { children } = props

  const [showSponsors, setShowSponsors] = React.useState(false)

  const defaultValue = {
    showSponsors,
    setShowSponsors,
  }

  return (
    <AppStateContext.Provider value={defaultValue}>
      {children}
    </AppStateContext.Provider>
  )
}
