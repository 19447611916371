import React from 'react'

interface VerticalSpacerProps {
  size: number | 'lg' | 'md' | 'sm'
}

export const VerticalSpacer: React.FC<VerticalSpacerProps> = (props) => {
  const { size } = props

  return (
    <div
      style={{
        height:
          size === 'lg'
            ? '100px'
            : size === 'md'
            ? '50px'
            : size === 'sm'
            ? '20px'
            : size,
      }}
    />
  )
}
