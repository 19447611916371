import React, { ReactNode } from 'react'
import { Button, Container, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Navigation.scss'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { LinkContainer } from 'react-router-bootstrap'
import { FirebaseContext } from '../FirebaseContext'

type NavDropdownProps = {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  children: ReactNode
}

export const Navigation: React.FC = () => {
  const CustomToggle = React.forwardRef<HTMLInputElement, NavDropdownProps>(
    ({ onClick, children }, ref) => (
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
        className="NavDropdown"
      >
        {children}
      </div>
    )
  )

  return (
    <div className="NavContainer">
      <Container>
        <nav className="Nav">
          <div className="NavSection">
            <Link className="NavChild" to="/">
              <img
                className="Logo"
                src="/logo.svg"
                alt="Flinders Ultimate Logo"
              ></img>
            </Link>
            <Link to="/" className="NavChild SiteTitle">
              Flinders Ultimate
            </Link>
          </div>
          <div className="NavSection">
            <Link className="NavChild" to="/news">
              News
            </Link>
            <Dropdown className="NavChild">
              <Dropdown.Toggle as={CustomToggle} id="about-dropdown">
                About <img
                className="external"
                src="/icons/ChevronDownDark.svg"
                alt="Dropdown Icon"
              />
              </Dropdown.Toggle>
              <Dropdown.Menu align={'right'} className="DropdownMenu">
                <LinkContainer to="/contact">
                  <Dropdown.Item>Contact</Dropdown.Item>
                </LinkContainer>
                {/* <LinkContainer to="/results">
                  <Dropdown.Item>Results</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/members">
                  <Dropdown.Item>Members</Dropdown.Item>
                </LinkContainer> */}
                <LinkContainer to="/resources">
                  <Dropdown.Item>Resources</Dropdown.Item>
                </LinkContainer>
                {/* <LinkContainer to="/sponsors">
                  <Dropdown.Item>Sponsors</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/stories">
                  <Dropdown.Item>Stories</Dropdown.Item>
                </LinkContainer> */}
              </Dropdown.Menu>
            </Dropdown>
            <a className="NavChild" href="https://play.flindersultimate.club" rel="external">
              Play
              <img
                className="external link-external"
                src="/icons/ExternalLink-Dark.svg"
                alt="External Link Icon"
              />
            </a>
            {/* <Dropdown className="NavChild">
              <Dropdown.Toggle as={CustomToggle} id="about-dropdown">
                Play <ArrowDropDownIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu align={'right'} className="DropdownMenu">
                <LinkContainer to="/ultimate">
                  <Dropdown.Item>How to Play</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/register">
                  <Dropdown.Item>Become a Member</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/events">
                  <Dropdown.Item>Events</Dropdown.Item>
                </LinkContainer>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </nav>
      </Container>
    </div>
  )
}
