import React from 'react'
import { Button, Col, ListGroup, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'

export const Resources: React.FC = () => {
  return (
    <>
      <p>
        We are a SAFDA Affiliated Club and as such operate under SAFDA policies
        and guidelines. All policies are superceded by internal documents.
      </p>
      <Row>
        <Col>
          <h5>Club Documents</h5>
          <ListGroup>
            <ListGroup.Item>
              <h6>Constitution</h6>
              <p>21/9/2020</p>
              <a href="https://docs.google.com/document/d/e/2PACX-1vQcOLhtRI0B7ZFMoL1GOZKlkKhggSrZYFjM9Yle_O5VSwEn2tP15Ek7j3OCp-k2P07v7tILYuWtFMw3/pub">
                Read via Google Docs
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <h6>Membership Conditions</h6>
              <p>21/2/2021</p>
              <a href="https://docs.google.com/document/d/e/2PACX-1vTQTzR_CvHEDcmAu9W7HtHVyXDB9JFIXaFoXF3sL1BM7dvr08jxQ0DryS6KbSItowG8qD4qhDnHtjmp/pub">
                Read via Google Docs
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <h6>FUSL Laws</h6>
              <p>31/8/2020</p>
              <a href="https://docs.google.com/document/d/e/2PACX-1vQOZ4CLku7HxdPN3k6ZhVoo4cFtCPnL4ybxJCXtSSxupoKJJx02xrSfaDyD9-Yhz7tzVPWwNqBpkc7T/pub">
                Read via Google Docs
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <h5>Policies</h5>
          <ListGroup>
            <ListGroup.Item>
              <h6>AFDA Member Protection Policy</h6>
              <p>9/1/2017</p>
              <a href="https://afda.com/m/afda-mpp-v5-0-20161231">Read via afda.com</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <h6>AFDA Code of Conduct</h6>
              <p>30/7/14</p>
              <a href="https://afda.com/m/afda-code-of-conduct">Read via afda.com</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <h6>AFDA Lightning Policy</h6>
              <p>3/3/16</p>
              <a href="https://afda.com/m/lightning-policy-20140917-2">
                Read via afda.com
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <h6>SAFDA Extreme Weather Policy</h6>
              <p>12/11/18</p>
              <a href="https://saultimate.net/m/safda-extreme-weather-policy-oct18">
                Read via saultimate.net
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  )
}
