import React from 'react'
import {
  useCollection,
  useCollectionData,
} from 'react-firebase-hooks/firestore'
import { FirebaseContext } from '../FirebaseContext'
import { Player } from '../types/Types'

export const usePlayers = () => {
  const { db } = React.useContext(FirebaseContext)
  const query = db.collection('players')
  const options = {
    snapshotListenOptions: { includeMetadataChanges: false },
    idField: 'id',
  }
  return useCollectionData<Player>(query, options)
}
