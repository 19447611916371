import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import { AppStateProvider } from './AppStateContext'
import { FirebaseProvider } from './FirebaseContext'
import reportWebVitals from './reportWebVitals'
// @ts-ignore
import { FacebookProvider } from 'react-facebook'
import { ParallaxProvider } from 'react-scroll-parallax'

require('dotenv').config()

ReactDOM.render(
  <React.StrictMode>
    <AppStateProvider>
      <FirebaseProvider>
        <HelmetProvider>
          <FacebookProvider
            appId="825856021649843"
          >
            <ParallaxProvider>
              <App />
            </ParallaxProvider>
          </FacebookProvider>
        </HelmetProvider>
      </FirebaseProvider>
    </AppStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
