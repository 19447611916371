import React from 'react'
import {
  useCollection,
  useCollectionData,
  useCollectionDataOnce,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore'
import { FirebaseContext } from '../FirebaseContext'
import { BlogPost, Player } from '../types/Types'
import firebase from 'firebase/app'

export const useBlogPosts = () => {
  const { db } = React.useContext(FirebaseContext)
  const query = db.collection('posts')
  const options = {
    snapshotListenOptions: { includeMetadataChanges: false },
    idField: 'id',
  }
  return useCollectionData<BlogPost>(query, options)
}

export const useBlogPost = (slug: string) => {
  const { db } = React.useContext(FirebaseContext)
  const query = db.collection('posts').where('slug', '==', slug)
  const options = {
    snapshotListenOptions: { includeMetadataChanges: false },
    idField: 'id',
  }
  const [articles, loading, error] = useCollectionDataOnce<BlogPost>(
    query,
    options
  )

  const article: BlogPost | undefined = (articles && articles[0]) ?? undefined

  return [article, loading, error] as const
}

// export const useArticle = (slug: string) => {
//   const { db } = React.useContext(FirebaseContext)

//   const [value, setValue] = React.useState<BlogPost | undefined>(undefined)
//   const [loading, setLoading] = React.useState<boolean>(true)
//   const [error, setError] = React.useState<string | null>(null)

//   // const [value, loading, err] = useBlogPosts()
//   // const ref = value?.find(it => {it.slug === slug})
//   const ref = db.collection('posts').where('slug','==',slug).get().then(results => {
//     setLoading(false)
//     if (results.size < 1) {
//       setError('not found')
//       return
//     }
//     if (results.size == 1) {
//       const newState = results.docs[0].data() as BlogPost
//       setValue(newState)
//     }
//   })

//   return { value, loading, error }
//   // return useDocumentDataOnce<BlogPost>(ref, options)
// }
