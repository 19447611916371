import React from 'react'
import { Link } from 'react-router-dom'
import { MailingList } from '../components/MailingList'
import './Home.scss'
// @ts-ignore
import { Page, CustomChat, Group } from 'react-facebook'
import { VerticalSpacer } from '../components/VerticalSpacer'
import { GoogleMap } from '../components/GoogleMap'
import { Button, Container, Image, Table } from 'react-bootstrap'
import { Carousel } from 'react-bootstrap'
import { Parallax } from 'react-scroll-parallax'
import { Testimonials } from '../components/Testimonials'
// @ts-ignore
import { Fade } from 'react-reveal'

export const Home: React.FC = () => {

  const signUpRef = React.useRef<HTMLDivElement>(null)

  const scrollToSignUp = () => {
    if (!signUpRef.current) { 
      return 
    } else {
      window.scrollTo({
        behavior: "smooth",
        top: signUpRef.current.getBoundingClientRect().top
      })
    }
  }

  return (
    <>
      <CustomChat
        pageId="325245030953782"
        minimized={false}
      />
      <div className="Home">
        <Parallax >
          <div className="SplashImage">
            <Container style={{ position: 'relative' }}>
              <Fade bottom cascade>
                <div className="SplashCTA">
                  <h2>
                    We're an Ultimate Frisbee club in the South of Adelaide.
                  </h2>
                  <p>
                    We've been playing frisbee at Flinders University since 2003! Most people join our club to play our famous Monday night Social League, but we also have Mixed, Men's, and Women's teams for other competitions. Our doors are always open for new student and non-student members.
                  </p>
                  <p>
                    Ultimate Frisbee is a fast-growing sport in SA and we are proud to be growing and developing new talent. If you are interested in picking up Ultimate - no experience required! - please get in touch with us.
                  </p>
                  <Button variant="dark" onClick={() => { scrollToSignUp() }}>Sign up now</Button>
                </div>
              </Fade>
            </Container>
            <Image rounded fluid src="/WebsiteSplash.png" alt="A male frisbee player makes a diving catch for a disc."></Image>
          </div>
        </Parallax>
        <Container>
          <div className="Section">
            <div className="Content">
              <h3>What is Ultimate Frisbee?</h3>
              <p>
                Ultimate Frisbee is a fast-paced, non-contact sport with rules
                similar to those of Rugby and Netball. Teams play on offence and
                defence to try and catch the Disc in an endzone - and to try and
                stop the other team from doing so. You lose possession by not
                completing a pass to a teammate - called a turnover. You cannot move
                with the disc and you have 10 seconds to complete a pass. Ultimate
                is a self-refereed sport with a heavy focus on the Spirit of the
                Game.
              </p>
              <Link to="/rules">Read more about the rules here.</Link>
            </div>
          </div>
          <div className="Section Split">
            <Fade bottom cascade>
              <div className="SectionHalf">
              <h2>When do we play?</h2>
              <p>
                We play frisbee the whole year round. 
                Mondays are our main event night, featuring an open training followed by our extremely popular Social League, which runs during each University semester. 
                Our teams also have training on Friday nights, which is open to all club members.
              </p>
              <p>
                We have opportunities for all levels of play. If you're just starting out, trainings and Social League is a great place to start.
                If you want to challenge yourself, give our Draft League a go, or try out for our State or University teams. 
              </p>
              <p>
                When there isn't a formal league running, we're usually in the same place playing casual pick-up games 
                (make sure you check our <a href="https://www.facebook.com/groups/242322592460982">Facebook group</a> for updates). 
                There's almost always frisbee happening, <Link to="/contact">get in touch with us</Link> and we can help you get started!
              </p>
              {/* <Image src="/orange.jpg" fluid rounded></Image> */}
              </div>
              <div className="SectionHalf">
                <strong>Training Times</strong><br/>
                <VerticalSpacer size="sm"/>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Time</th>
                      <th>Venue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Club Training</td>
                      <td>Monday 6pm - 7pm <i>*</i></td>
                      <td>Flinders University Main Oval</td>
                    </tr>
                    <tr>
                      <td>Fury Training</td>
                      <td>Friday 6pm - 8pm <i>*</i></td>
                      <td>Flinders University Main Oval</td>
                    </tr>
                  </tbody>
                </Table>
                <i>* Check Facebook for updates and cancellations.</i>
                <VerticalSpacer size="md"/>
                <strong>Leagues</strong>
                <VerticalSpacer size="sm"/>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Day</th>
                      <th>Venue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Social League</td>
                      <td>Monday</td>
                      <td>Flinders University Main Oval</td>
                    </tr>
                    <tr>
                      <td>Draft League</td>
                      <td>Monday</td>
                      <td>Flinders University Main Oval</td>
                    </tr>
                    <tr>
                      <td>State League</td>
                      <td>Wednesday</td>
                      <td>Adelaide Lutheran Sports Club</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Fade>
          </div>
          <div className="Section">
            <Fade bottom>
              <h2>Venue</h2>
              <p>
                We proudly play at one of the most picturesque places to play Ultimate in the State. 
                With a backdrop of Adelaide sunsets, exceptional facilities, easy parking, and accessibility to public transport, it's easy to see why so many people choose to spend their Monday nights at Flinders. 
              </p>
              <p>
                Our home venue is the Flinders University Main Oval, located by the University with best parking at Car Park 12 (free after 6pm).
                The clubrooms are a stone's throw away from the new Flinders Train Station and the Flinders Medical Centre bus interchange.
                If you're lost, head towards the Pavilion and look out for flying discs!
              </p>
              <VerticalSpacer size="md" />
              <GoogleMap />
            </Fade>
          </div>
          <div className="Section">
            <Testimonials />
          </div>
          <div className="Section Split GetStarted" ref={signUpRef}>
            <div className="SectionHalf GetStarted">
              <div>
                <Fade bottom cascade>
                  <h2>How do I get involved?</h2>
                  <VerticalSpacer size="sm"></VerticalSpacer>
                  <p>Ready to get started? Follow these steps!</p>
                  <ol>
                    <li>Grab a Club Membership and your first disc <a href="https://play.flindersultimate.club">here.</a></li>
                    <li>Recruit a friend! It's hard to practise throwing by yourself.</li>
                    <li>Subscribe to our Mailing List, follow our <a href="https://facebook.com/FlindersUltimate">Facebook page</a>, and join our <a href="https://www.facebook.com/groups/242322592460982">Facebook group</a> to make sure you don't miss out on any news.</li>
                    <li>Come out to training on a Monday or Friday night!</li>
                  </ol>
                  <VerticalSpacer size="sm"></VerticalSpacer>
                  <p>Have questions? Say hi! <Link to="/contact">Contact us</Link> or send us a message on Facebook. We'll help you get registered and let you know what's on.</p>
                </Fade>
              </div>
            </div>
            <div className="SectionHalf">
            {/* <FacebookStuff /> */}
            </div>
          </div>
          <div className="Section MailingList">
            <h2>Don't miss a thing.</h2>
            <p>Join our mailing list to stay in the loop.</p>
            <MailingList></MailingList>
          </div>  
          <VerticalSpacer size="md"></VerticalSpacer>
          <Image fluid rounded src="/GoodSportsWebBanner.jpg" alt="Good Sports logo. We are an accredited Good Sports Club."></Image>
        </Container>
      </div>
    </>
  )
}

const FacebookStuff: React.FC = () => {
  return (
    <>
      <Page 
        href="https://www.facebook.com/FlindersUltimate"
        width="300"
        hideCTA 
        hideCover 
      />
      <VerticalSpacer size="md"></VerticalSpacer>
      <Group
        href="https://www.facebook.com/groups/242322592460982"
        width="300"
        showMetaData={true}
      />
    </>
  )
}