import React from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { useFormFields, useMailChimpForm } from 'use-mailchimp-form'
import './MailingList.scss'

const url: string = process.env.REACT_APP_MAILCHIMP_URL || ''

export const MailingList: React.FC = () => {
  const { loading, error, success, message, handleSubmit } =
    useMailChimpForm(url)

  const { fields } = useFormFields({
    EMAIL: '',
  })

  return (
    <div className="MailingList">
      <Form
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit(fields)
        }}
      >
        <Form.Row>
          {/* TODO: Make this full width */}
          <Col md={6} sm={6} xs={8}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              onChange={(e) => {
                fields.EMAIL = e.target.value
              }}
              placeholder="name@example.org"
            />
            <Form.Text muted>
              {error && <div dangerouslySetInnerHTML={{ __html: message }} />}
              {success && <div dangerouslySetInnerHTML={{ __html: message }} />}
            </Form.Text>
          </Col>
          <Col>
            <Button variant="dark" type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}
