import { DateTime } from 'luxon'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import ReactMarkdown from 'react-markdown'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Page } from '../components/Page'
import { useBlogPost, useBlogPosts } from '../hooks/useBlogPosts'
import { BlogPost, Player } from '../types/Types'
import { NotFound } from './NotFound'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import './ArticleLoader.scss'
import { VerticalSpacer } from '../components/VerticalSpacer'
import { FirebaseContext } from '../FirebaseContext'
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import { Helmet } from 'react-helmet-async'

interface ArticleLoaderParams {
  slug: string
}

export const ArticleLoader: React.FC = () => {
  const { slug } = useParams<ArticleLoaderParams>()

  // const [articles, loading, error] = useBlogPosts()

  const [article, loading, error] = useBlogPost(slug)

  // const [article, setArticle] = React.useState<BlogPost | undefined>(undefined)

  // React.useEffect(() => {
  //   setArticle(articles?.find(it => it.slug === slug))
  // }, [articles])

  if (article) {
    // const [ author, loading, error ] = useDocumentDataOnce<Player>(article.author,{})

    return (
      <Page>
        <BackButton />
        <Article article={article} />
      </Page>
    )
  }

  if (!loading && !article) {
    return (
      <Page>
        <BackButton />
        <NotFound />
        <pre>{error && error.message}</pre>
      </Page>
    )
  }

  return (
    <Page>
      <BackButton />
      <VerticalSpacer size={'lg'} />
      {loading && <Spinner variant="primary" animation="border"></Spinner>}
    </Page>
  )
}

const BackButton = () => {
  return (
    <div className="BackButton">
      <KeyboardBackspaceIcon className="BackIcon" />
      Back to{' '}
      <Link to="/news" className="BackButton">
        {' '}
        News
      </Link>
    </div>
  )
}

interface ArticleProps {
  article: BlogPost
}

const Article: React.FC<ArticleProps> = (props) => {
  const { article } = props

  const dt = DateTime.fromSeconds(article.date.seconds)
  const shareURL =
    (process.env.REACT_APP_BASE_URL ?? 'https://flindersultimate.club') +
    useLocation().pathname

  const previewLength = 100
  const snippet =
    article.content.substring(0, previewLength) +
    `${article.content.length > previewLength ? '...' : ''}`
  const preview = snippet.replace(/[`~@#$%^&*()_|+\=<>\{\}\[\]\\\/]/gi, '')

  const [author, loading, error] = useDocumentDataOnce<Player>(article.author, {
    idField: 'id',
  })

  return (
    <>
      <Helmet>
        <title>{article.title} - Flinders Ultimate</title>
        <meta property="og:title" content={`${article.title} - Flinders Ultimate`} />
        <meta property="og:url" content={shareURL} />
        <meta property="og:description" content={preview} />
        <meta property="og:image" content={article.imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="play.flindersultimate.club" />
        <meta property="twitter:url" content={shareURL} />
        <meta name="twitter:title" content={`${article.title} - Flinders Ultimate`} />
        <meta name="twitter:description" content={preview} />
        <meta name="twitter:image" content={article.imageUrl} />
      </Helmet>
      <div className="Article">
        <h1>{article.title}</h1>
        <div className="ArticleSubheading">
          <div className="CardDetails">
            {!loading && (
              <p>
                By {author?.name} at{' '}
                {dt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
              </p>
            )}
          </div>
          <div className="ShareButtons">
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} borderRadius={3} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} borderRadius={3} round />
            </TwitterShareButton>
          </div>
        </div>
        <img className="" src={article.imageUrl} />
        <ReactMarkdown>{article.content}</ReactMarkdown>
      </div>
    </>
  )
}
