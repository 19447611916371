import firebase from 'firebase'
import React from 'react'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// TODO: This may need to be conditional on if firebase has already been initialized.
firebase.initializeApp(firebaseConfig)

export interface FirebaseContextShape {
  firebase: firebase.app.App
  auth: firebase.auth.Auth
  db: firebase.firestore.Firestore
  user: firebase.User | null
  storage: firebase.storage.Storage
  analytics: firebase.analytics.Analytics
  // authProviders: firebase.auth.AuthProvider[] TODO Add Auth Providers
}

type UserState = firebase.User | null

export const FirebaseContext = React.createContext({} as FirebaseContextShape)

export const FirebaseProvider: React.FC = (props) => {
  const { children } = props

  const [currentUser, setCurrentUser] = React.useState<UserState>(null)

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(setCurrentUser)
  }, [])

  const defaultValue: FirebaseContextShape = {
    firebase: firebase.app(),
    auth: firebase.auth(),
    db: firebase.firestore(),
    storage: firebase.storage(),
    analytics: firebase.analytics(),
    user: currentUser,
  }

  return (
    <FirebaseContext.Provider value={defaultValue}>
      {children}
    </FirebaseContext.Provider>
  )
}
