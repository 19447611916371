import firebase from 'firebase'
import React from 'react'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import { MailingList } from '../components/MailingList'
import { VerticalSpacer } from '../components/VerticalSpacer'
import { FirebaseContext } from '../FirebaseContext'
import { ContactSubmission } from '../types/Types'
import './Contact.scss'

type Response = {
  value: 'success' | 'error' | undefined
  loading: boolean
}

export const Contact: React.FC = () => {
  const { db } = React.useContext(FirebaseContext)

  const seconds = Math.round(new Date().getTime() / 1000)

  const initialState: ContactSubmission = {
    name: '',
    email: '',
    message: '',
    date: new firebase.firestore.Timestamp(seconds, 0),
    id: db.collection('contact-submissions').doc().id,
  }

  const [editValue, setEditValue] = React.useState(initialState)
  const [response, setResponse] = React.useState<Response>({
    value: undefined,
    loading: false,
  })
  const [allowSubmit, setAllowSubmit] = React.useState(false)

  const handleInputFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setEditValue({
      ...editValue,
      [e.target.name]: value,
    })
  }

  const handleCaptchaChange = (token: string | null) => {
    if (token) {
      setAllowSubmit(true)
    } else {
      setAllowSubmit(false)
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setResponse({ ...response, loading: true })
    // Verify fields
    db.collection('contact-submissions')
      .doc(editValue.id)
      .set(editValue)
      .then(() => {
        setResponse({ loading: false, value: 'success' })
      })
      .catch((err) => {
        setResponse({ loading: false, value: 'error' })
      })
  }

  return (
    <>
      <p>
        Need to get in touch with us? Shoot us an email at{' '}
        <a href="mailto:flindersultimate@gmail.com">
          flindersultimate@gmail.com
        </a>{' '}
        or fill out the contact form below.
      </p>
      <p>
        Please allow some time for one of our volunteers to get back to you.
      </p>
      <VerticalSpacer size={'sm'} />
      {(!response.value &&
        (!response.loading ? (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} sm={8} xs={12}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type={'text'}
                    placeholder={'Matthew Flinders'}
                    onChange={handleInputFieldChange}
                    name="name"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type={'email'}
                    placeholder={'cool-email@example.com'}
                    onChange={handleInputFieldChange}
                    name="email"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as={'textarea'}
                rows={3}
                placeholder={'Enter your message here.'}
                onChange={handleInputFieldChange}
                name="message"
              />
            </Form.Group>
            <ReCAPTCHA
              sitekey="6Ldc-vQaAAAAAM6SeVTWwpp0n4fK1Z3U45eehWT7"
              onChange={handleCaptchaChange}
            />
            <Button
              variant="dark"
              type="submit"
              disabled={!allowSubmit}
              style={{ marginTop: '1em' }}
            >
              Send
            </Button>
          </Form>
        ) : (
          <Spinner animation="border" />
        ))) ||
        (response.value === 'success' && <i>Thank you for your message!</i>) ||
        (response.value === 'error' && (
          <i>
            Something went wrong. Please try contacting us via email instead.
          </i>
        ))}
      <VerticalSpacer size={'lg'} />
      <p className="MailingListCTA">
        Don't have a specific query but want to just stay in the loop? Join our
        mailing list for updates on teams, events, and offers throughout the
        season.
      </p>
      <VerticalSpacer size={'sm'} />
      <MailingList />
    </>
  )
}
