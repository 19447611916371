import React from 'react'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import { FirebaseContext } from '../../FirebaseContext'

import 'react-markdown-editor-lite/lib/index.css'

import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import InboxIcon from '@material-ui/icons/Inbox'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SpeedIcon from '@material-ui/icons/Speed'
import PersonIcon from '@material-ui/icons/Person'

import './Admin.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { Helmet } from 'react-helmet-async'
import { Articles } from './Articles'
import { Inbox } from './Inbox'
import { PlayerData } from './PlayerData'
import { Analytics } from './Analytics'
import { Dashboard } from './Dashboard'

type AdminTab = {
  name: string
  icon: React.ReactNode
}

const tabs: AdminTab[] = [

  {
    name: 'Articles',
    icon: <VerticalSplitIcon />,
  },
  {
    name: 'Inbox',
    icon: <InboxIcon />,
  },
]

// {
//   name: 'Dashboard',
//   icon: <DashboardIcon />,
// },
// {
//   name: 'Analytics',
//   icon: <SpeedIcon />,
// },
// {
//   name: 'Player Data',
//   icon: <PersonIcon />,
// },

export const Admin: React.FC = () => {
  const { user: currentUser, db } = React.useContext(FirebaseContext)
  const [selectedTab, setSelectedTab] = React.useState<AdminTab>(tabs[0])

  if (!currentUser) {
    ;<Redirect to="/" />
  }

  return (
    <div className="Admin">
      <Helmet>
        <title>Admin Panel - Flinders Ultimate</title>
      </Helmet>
      <div className="SidePanel">
        <Link to="/" style={{ marginBottom: '4em' }}>
          <img className="Logo" src="logo-light.svg"></img>
          <b>Admin Panel 😎</b>
        </Link>
        {tabs.map((tab, it) => {
          return (
            <div
              className={`SideCategory ${
                selectedTab.name === tab.name ? 'Selected' : ''
              }`}
              key={`sideicon-${it}`}
            >
              <a
                onClick={() => {
                  setSelectedTab(tab)
                }}
              >
                <span className="SideIcon">{tab.icon}</span>
                {tab.name}
              </a>
            </div>
          )
        })}
      </div>
      <div className="ContentPanel">
        {(selectedTab.name === 'Articles' && <Articles />) ||
          (selectedTab.name === 'Inbox' && <Inbox />) ||
          (selectedTab.name === 'Player Data' && <PlayerData />) ||
          (selectedTab.name === 'Analytics' && <Analytics />) ||
          (selectedTab.name === 'Dashboard' && <Dashboard />)}
      </div>
    </div>
  )
}
