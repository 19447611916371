import { DateTime } from 'luxon'
import React from 'react'
import { Table } from 'react-bootstrap'
import { useContactSubmissions } from '../../hooks/useContactSubmissions'
import { ContactSubmission } from '../../types/Types'

export const Inbox: React.FC = () => {
  const [submissions, loading, error] = useContactSubmissions()

  return (
    <div>
      <h3>Inbox</h3>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>{/* Button Column */}</th>
          </tr>
        </thead>
        <tbody>
          {submissions &&
            submissions.map((it, i) => {
              return <InboxRow key={`inboxrow-${i}`} submission={it} />
            })}
        </tbody>
      </Table>
    </div>
  )
}

interface InboxRowProps {
  submission: ContactSubmission
}

const InboxRow: React.FC<InboxRowProps> = (props) => {
  const { submission } = props

  const dt = DateTime.fromSeconds(submission.date.seconds)

  return (
    <tr>
      <td>{dt.toLocaleString(DateTime.DATETIME_MED)}</td>
      <td>{submission.name}</td>
      <td>{submission.email}</td>
      <td>{submission.message.substr(0, 30) + '...'}</td>
      <td></td>
    </tr>
  )
}
